import React from 'react';
import AppRoutes from './AppRoutes/AppRoutes';

const App = () => {
  return (
    <AppRoutes />
  );
};

export default App;
